<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32  w-full font-sans text-black">
        <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Origen y evolución de los reality shows: <br class="md:block hidden"> una mirada sociológica y de comunicación</h1>
        <div class="flex flex-col justify-center items-center w-full">
            <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
                <img src="/images/origen-reality-show-big-brother-analisis-social-y-comunicacion.webp" alt="El origen de los reality shows: el Gran Hermano te observa." class="mb-6">
                <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
                    <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tendencias" class=" text-hone">Tendencias</router-link></p>
                    <div class="flex items-start justify-start">
                        <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">13 de septiembre de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
                    </div>
                    <p class="md:mb-4 mb-6"><b>Extracto:</b> Este artículo profundiza en el origen y evolución de los reality shows desde una perspectiva sociológica y comunicativa. Analiza cómo este fenómeno televisivo ha moldeado la cultura popular, ofreciendo un enfoque académico pero accesible.</p>
                    <p class="md:mb-4 mb-6"><b>Introducción<br> Los reality shows se han convertido en un fenómeno global</b> que trasciende el entretenimiento puro y toca aspectos profundos de la vida social y cultural. Desde su surgimiento, con programas experimentales, hasta su explosión internacional a finales de los 90, han evolucionado de manera significativa.</p>
                    <p class=" md:mb-8 mb-10">Actualmente, <b>formatos como <em>La Casa de los Famosos México 2</em>, no sólo capturan la atención de millones de espectadores, también actúan como un reflejo de los valores, tensiones y dinámicas sociales</b>. Este artículo analiza cómo los reality shows han cambiado con el tiempo, su influencia sociológica, y cómo la audiencia se ha convertido en una clave en su éxito.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La televisión de la vida real o telerrealidad</h2>
                    <p class="md:mb-4 mb-6">Para iniciar este artículo, <b>es indispensable explicar el concepto de televisión de la vida real o telerrealidad</b>, un género que evoluciona rápidamente, y que integra algunas características de los documentales, de la ficción, de los concursos y el espectáculo.</p>
                    <p class="md:mb-4 mb-6">Por su carácter híbrido, la telerrealidad se ha transformado: ha pasado de ser programas que recreaban acontecimientos, bromas a las personas en la calle, acciones de equipos de emergencia, grabaciones a personas durante determinado tiempo y vídeos amateurs, a incluir cámaras en todo momento: las 24 horas del día, los siete días de la semana, y “entrar” por así decirlo, en la vida de personas anónimas seleccionadas para concursar, como en la de personajes famosos.</p>
                    <p class="md:mb-4 mb-6">Las autoras Su Holmes y Deborah Jermyn en su libro <em>Comprendiendo la televisión de realidad</em>, mencionan que lo que tienen en común estos programas es subrayar que su estrategia enunciativa es lo real, lo que es y lo que existe. </p>
                    <p class="md:mb-8 mb-10">De esta manera, <b>entenderemos la telerrealidad como programas televisivos protagonizados por personas que presentan hechos reales y espontáneos</b>, con una narrativa propia y que pueden concursar o no por algún beneficio económico.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Los primeros reality shows o televisión de la vida real: Origen histórico</h2>
                    <p class="md:mb-4 mb-6">El concepto de <b>reality show, tal como lo conocemos hoy, tiene sus raíces en programas estadounidenses como <em>Candid camara</em> (1948)</b> en el cual las personas eran sorprendidas con bromas previamente planeadas, sin embargo, la reacción era muy natural.</p>
                    <p class="md:mb-4 mb-6">También, <b>eran televisados los concursos de belleza como <em>Miss America</em></b>, esto en los años 50. En el año de 1967, <b>el programa alemán <em>Aktenzeichen XY...</em> se convirtió en el precedente de las series <em>crime time</em></b>, ya que en un estudio de televisión se recreaban crímenes sin resolver y se le pedía a los televidentes que si tenían alguna información, la compartieran. Así surgió ese género.</p>
                    <p class="md:mb-4 mb-6"><b>Es en 1973</b>, cuando la estación PBS, de Estados Unidos, <b>comienza a transmitir el documental televisivo <em>An American Family</em>, pionero al seguir el día a día de los Loud, una familia californiana</b> de clase media, que mostraba la vida real de sus integrantes, la pareja Bill y Pat, y sus cinco hijos Lance, Kevin, Grant, Delilah y Michele. Mostraba los conflictos y momentos emotivos que contrastaban con la idea de familia ideal americana.</p>
                    <p class="md:mb-8 mb-10"><b>Este programa logró que más de diez millones de espectadores estuvieran al pendiente de cada uno de sus episodios</b>. Así, <b>se convirtió en un parteaguas sociológico</b>, pues ofrecía una ventana a los cambios que se desarrollaban en la sociedad estadounidense: la transformación de los roles familiares, la recesión de la economía de los 70 y las expectativas sociales.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">El reality show en los años 90: Las primeras variantes</h2>
                    <p class="md:mb-8 mb-10"><b>Los programas de <em>crime-time</em> o de crímenes sin resolver, así como los de accidentes y emergencias o persecuciones</b> como COPS (Estados Unidos, 1989 y con 28 temporadas), <b>así como los <em>talk shows</em> como <em>The Oprah Winfrey Show</em></b> fueron los formatos que a finales de los 80 y principios de los 90, se repitieron en otros países y tenían cautivo a un público que quería más novedades.</p>
                    <img src="/images/origen-reality-shows-experimento-biosfera2-precedente-big-brother.webp" alt="<em>Biosfera 2</em>: el experimento que salió mal, pero fue el precursor de <em>Big Brother</em>." class="md:mb-8 mb-10">
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone"><em>Biosfera 2</em>: Reality show 0.0</h2>
                    <p class="md:mb-4 mb-6">Pero no sólo en el ámbito del entretenimiento se necesitaba ser innovador. <b>En el año de 1991, un grupo de ocho científicos formó parte de un experimento sin precedentes: se llamó <em>Biosfera 2</em></b>, que buscó comprobar si un grupo de seres humanos podría recrear los distintos ecosistemas y, en caso de conquistar otros planetas, llevarse un “pedacito de planeta Tierra” a otros lugares.</p>
                    <p class="md:mb-4 mb-6">A este experimento <b>se le sumó la vigilancia constante, las 24 horas del día, los siete días de la semana. Los científicos estuvieron encerrados por dos años</b>. A estas personas se les llamó los biosferinos.</p>
                    <p class="md:mb-4 mb-6">El desierto de Arizona fue el lugar en el que John Polk Allen, ingeniero graduado de Harvard, diseñó y compró un espacio para desarrollar este ecosistema, al que también llamó “Jardín del Edén”, ya que la hipótesis era que al tener una biosfera artificial y los insumos necesarios, los seres humanos sobrevivirían, ya que dotaron este lugar con cultivos, animales, peces y demás.</p>
                    <p class="md:mb-4 mb-6"><b>Desafortunadamente, el experimento terminó muy mal</b>. Trataron de generar su propio oxígeno con la fotosíntesis de las plantas, cosa que no funcionó porque las plantas y los cultivos comenzaron a llenarse de plagas; los animales se enfermaron y murieron por falta de comida, por ende, los científicos también tuvieron que comenzar a racionar lo poco que tenían y por la falta de oxígeno, comenzaron a tener problemas en su salud física y mental, lo que acarreo comportamientos extremos.</p>
                    <p class="md:mb-4 mb-6">Así, <b>se confirmó que ni los más grandes científicos pueden reproducir lo que el planeta Tierra es capaz de hacer y mantener como lo son los ecosistemas</b>.</p>
                    <p class="md:mb-8 mb-10"><b>A <em>Biosfera 2</em> podríamos llamarla el <em>Big Brother</em> 0.0</b> porque, si bien no se transmitía lo que sucedía adentro, los medios de comunicación de entonces estaban al tanto e informaban qué estaba pasando. La revista Times lo llamó como “el truco publicitario de 150 millones de dólares”.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone"><em>Big Brother</em>: Las reglas cambian... y la televisión, también</h2>
                    <p class="md:mb-4 mb-6">En el año 1949, <b>el escritor George Orwell, publicó la novela 1984, una obra distópica</b> que cuenta una historia futurista donde <b>un gobierno totalitario tiene el control absoluto</b> de sus ciudadanos, <b>y se representa con una figura omnipresente llamada el Gran Hermano (<em>Big Brother</em>)</b>. Aunque nunca aparece físicamente, su imagen se representa por todas partes, recordando que él tiene poder absoluto sobre su conducta, su pensamiento y la información que recibe.</p>
                    <p class="md:mb-4 mb-6">El visionario productor de televisión holandés, <b>John de Mol, tomó esta idea de aislar a las personas y grabarlas bajo condiciones extremas, y combinarla con la idea del control de la novela</b>, para crear uno de los reality shows más revolucionarios de la historia: <em>Big Brother</em>.</p>
                    <p class="md:mb-4 mb-6">Con su empresa Endemol, que fundó junto con quien había sido su competidor, <b>el productor Joop Van Den Ende</b>, posteriormente crearon otros realitys shows exitosos como <em>La Voz</em>, <em>Fear Factor</em>, y los derivados de esos como <em>Big Brother</em> VIP, y La Casa de los Famosos.</p>
                    <p class="md:mb-4 mb-6"><b>El primer <em>Big Brother</em> se llevó a cabo en el año de 1999 en su país, Holanda</b>, y se realizaron castings exhaustivos para seleccionar a las personas que entrarían a la casa.</p>
                    <p class="md:mb-4 mb-6"><b>Lo que buscaban los productores eran personalidades, edades y antecedentes diferentes</b>, para crear una dinámica lo más interesante posible y garantizar el entretenimiento del público.</p>
                    <p class="md:mb-4 mb-6"><b>Entre más conflictos e interacciones se desarrollaran en la casa, mejor</b>, porque la gente iba a verlo más. Por supuesto, iba a aumentar el rating, y también las ganancias de la televisora.</p>
                    <p class="md:mb-8 mb-10">Los seleccionados tenían que pasar tres días en un hotel donde nada más les abrían la puerta para darles la comida y listo. Así los prepararon para el encierro que iban a vivir.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La dinámica de <em>Big Brother</em>: reality show y competencia</h2>
                    <p class="md:mb-4 mb-6">De esta manera, <b>se sentó la base de una fórmula que habría de replicarse una y otra vez, con variantes en sus formatos y concursos</b>. Pero, de inicio, la dinámica funciona así.</p>
                    <p class="md:mb-4 mb-6">Dentro de la casa, <b>los participantes tienen que convivir y superar desafíos</b>; muchas veces de los resultados de esos desafíos, depende el presupuesto para la comida. Como mencionamos respecto a <em>Biosfera 2</em>, la comida puede escasear y los participantes pueden terminar comiendo lo que tengan a mano... o no comer.</p>
                    <p class="md:mb-4 mb-6"><b><em>Big Brother</em> de inicio era protagonizado por personas anónimas, pero al evolucionar, se sumaron personajes famosos</b>, algunos del mundo del espectáculo, política u otros ámbitos, dependiendo del contexto de cada país.</p>
                    <p class="md:mb-4 mb-6">El programa se transmitía las 24 horas al día, los siete días de la semana, con cámaras y micrófonos por todos lados. <b>Las acciones reales se desarrollaban delante de las cámaras y no había intervención de nadie de la producción</b>. Se crea una narrativa como si de una serie se tratara.</p>
                    <p class="md:mb-4 mb-6">Los participantes, de forma individual, nominan a dos personas que consideran deben abandonar la casa; los que tienen más votos son expulsados. <b>El público se suma a la dinámica, pues también puede votar por sus preferidos</b> o por quien quiere que sea eliminado.</p>
                    <p class="md:mb-4 mb-6">Además, <b>se cuenta con un confesionario, donde cada uno de los integrantes expresa lo que siente al estar en la casa</b>, sus conflictos y todo es observado.</p>
                    <p class="md:mb-8 mb-10"><b>Tiene que existir un ganador</b>: el último en permanecer en la casa, se llevará un premio, además del reconocimiento público, fama y lo que esto derive. Es un completo negocio.</p>
                    <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                        <ContactoVue class="m-3"/>
                    </div>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La estrategia para ganar <em>Big Brother</em>: aquí te decimos</h2>
                    <p class="md:mb-4 mb-6"><b>Estar en la casa de <em>Big Brother</em> requiere de una estrategia</b>, ya que la dinámica genera muchas tensiones y rivalidades. Pepe Herrera, quien ha ganado dos veces en España el <em>Gran Hermano</em>, es considerado uno de los mejores estrategas.</p>
                    <p class="md:mb-4 mb-6">Los 300 mil euros, como premio del <em>Gran Hermano</em> de España, fueron un incentivo para él. Una vez que avanzaron los castings, y que parecía que sí se iba a quedar, juntó a un grupo de cuatro expertos: un psicólogo, un guionista, un experto en marketing y su representante. <b>Ellos estudiaron todos los <em>Big Brothers</em> para saber cuáles fueron las estrategias</b> que usaron las personas que ganaron.</p>
                    <p class="md:mb-4 mb-6">En palabras de Pepe, <b>el <em>Gran Hermano</em> es un relato individual</b>. “Tienes que vender una historia. El guionista lo definió como el camino del héroe, que permea en la narrativa. <b>En las dos primeras semanas, tienes que ser el protagonista de la historia de todos los demás</b>. Interactuar con el resto de los participantes y que también crezcan sus historias”.</p>
                    <p class="md:mb-8 mb-10"><b>Esta fórmula puede ser replicada para todos los demás reality shows</b>, tomando en cuenta las variantes de cada programa y juego.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Reality shows games de los 2000: Survivor, Jersey Shore y más</h2>
                    <p class="md:mb-4 mb-6"><b>Tras el éxito de <em>Big Brother</em> en Holanda, el fenómeno se repitió globalmente</b>, y las versiones de cada país no se hicieron esperar.</p>
                    <p class="md:mb-4 mb-6">Y no sólo eso. La inmersión, por así decirlo, del público en el programa causó revuelo. Si <b><em>Big Brother</em> introdujo la fórmula multiplataforma</b> en cuanto a generar vínculos con la audiencia, la votación telefónica, la entrada de patrocinadores de productos diversos, la consecuencia fue que <b>los reality shows games se diversificaron</b>.</p>
                    <p class="md:mb-8 mb-10">Ejemplos de ellos fueron <em>Survivor</em> en Estados Unidos: consiste en sobrevivir a condiciones extremas en una isla;  <em>Jersey Shore</em> de MTV: jóvenes italoamericanos muestran su estilo de vida exagerado; en <em>American Idol</em> se integraron personajes del mundo de la música y a gente anónima con talento con inquietud de sobresalir. Todos se sumaron a los reality shows: el espectáculo, la moda, incluso, la gastronomía. Un ejemplo, <em>Hell’s Kitchen</em>.</p>
                    <img src="/images/big-brother-precedente-la-casa-de-los-famosos-2.webp" alt="La fórmula de <em>Big Brother</em> evolucionó en otros programas, como La Casa De Los Famosos 2." class="md:mb-8 mb-10">
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">México: El regreso de los reality shows y su impacto social y en la comunicación</h2>
                    <p class="md:mb-4 mb-6">Los reality shows, como decíamos al inicio del artículo, tienden a evolucionar rápidamente. Se han adaptado a estas últimas décadas y al contexto social de cada país en donde se llevan a cabo. México no es la excepción.</p>
                    <p class="md:mb-4 mb-6">Actualmente, casos que fueron mediáticos hace 20 años, vuelven a retomarse, por ejemplo, la reunión de integrantes de RBD, las bioseries de artistas como Luis Miguel, la moda de los 2000 que ha regresado, nos hacen sentir que estamos en un <em>déjà vu</em> cultural: ya lo habíamos vivido.</p>
                    <p class="md:mb-4 mb-6"><b>En la primera casa de <em>Big Brother</em> en México, se armó un escándalo con la sociedad mexicana</b> que, en esos años, era más conservadora. Se hicieron movimientos de asociaciones religiosas y a favor de la familia que pedían la cancelación del programa porque exponía la intimidad de los participantes.</p>
                    <p class="md:mb-8 mb-10">Además, el reality traspasó la llamada “cuarta pared”, y el público tenía el control de votar por su participante favorito, y en esos años, hasta las personas se endeudaban para emitir este voto telefónico. <b>Fue un formato innovador</b> en esa época.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué dice <em>La Casa de los Famosos México 2</em> sobre la sociedad?</h2>
                    <p class="md:mb-4 mb-6">En nuestro país, <b>este reality revela la fascinación por las celebridades</b> y, con el auge de las redes sociales, por los influencers, así como la llamada <em>contrieved reality TV</em> o el entretenimiento que se basa en los conflictos interpersonales.</p>
                    <p class="md:mb-4 mb-6">La figura del “famoso” es interesante, ya que en un contexto actual de la farándula mexicana, <b>ser famoso no necesariamente denota logros profesionales o artísticos, sino la interacción o exposición mediática</b>, ya que a menudo son personalidades de la televisión tradicional o influencers.</p>
                    <p class="md:mb-4 mb-6"><b>Este tipo de personajes son conocidos como “celebridad construida”</b> pues es un producto de esta era digital, donde la fama es efímera, basada en la cantidad de seguidores -o followers- en redes sociales, más que en un talento o carrera artística.</p>
                    <p class="md:mb-8 mb-10">Además, <b>el conflicto entre los participantes del programa puede reflejar las tensiones y polarización que existen en la sociedad mexicana:</b> las diferencias de clase, el machismo, los estereotipos de género y la competencia por el poder y la visibilidad.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La interacción audiencia-participantes: El poder de las redes sociales</h2>
                    <p class="md:mb-4 mb-6">Uno de los aspectos más fascinantes de los reality shows contemporáneos, y particularmente de <b><em>La Casa de los Famosos México 2</em>, es el rol activo de la audiencia</b>, ya que el modelo de participación es en tiempo real.</p>
                    <p class="md:mb-4 mb-6">A través <router-link to="/blog/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024" class=" text-htwo hover:text-hone">de las redes sociales</router-link> y las plataformas digitales, <b>los seguidores interactúan directamente</b> con la producción, influyen en el destino de los participantes y generan miles de contenidos en torno al show.</p>
                    <p class="md:mb-4 mb-6"><b>Este fenómeno es particularmente visible en Twitter, Instagram y TikTok, donde los fans discuten sobre los acontecimientos del programa, crean memes y viralizan momentos clave.</b></p>
                    <p class="md:mb-4 mb-6">La votación para eliminar participantes también se ha convertido en una herramienta poderosa que refuerza la sensación de que la audiencia tiene el control, lo que en última instancia mantiene su interés a lo largo de la duración del reality.</p>
                    <p class="md:mb-4 mb-6"><b>Desde el punto de vista de la comunicación, esta interacción en ambas direcciones representa un cambio radical en cómo los medios y el entretenimiento funcionan</b>. La audiencia ya no es pasiva, sino un actor fundamental que cocrea la narrativa del programa.</p>
                    <p class="md:mb-8 mb-10">Este tipo de dinámica no solo asegura la lealtad de los espectadores, también convierte a los participantes en personajes cuya popularidad puede fluctuar dependiendo de cómo sean percibidos por el público.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión: Los reality shows y las reflexiones desde la teoría crítica</h2>
                    <p class="md:mb-4 mb-6">Una de las reflexiones más importantes a los reality shows proviene de la <b>teoría crítica</b>, particularmente del concepto de <b>la sociedad del espectáculo</b>, desarrollado por el filósofo y teórico Guy Debord en la obra del mismo nombre de 1967.</p>
                    <p class="md:mb-4 mb-6">Según Debord, vivimos en una sociedad donde la vida misma se ha convertido en una representación, un espectáculo. <b>Este análisis se aplica directamente a los reality shows, donde la vida cotidiana de los participantes se convierte en un producto para el consumo del público.</b></p>
                    <p class="md:mb-4 mb-6">En este contexto, los reality shows como <em>La Casa de los Famosos México 2</em>, representan un claro ejemplo de cómo <b>el espectáculo se apodera de la realidad</b>. Los participantes no sólo se representan a sí mismos, también cumplen roles dentro de una narrativa impuesta por la producción y las expectativas del público o de sus seguidores.</p>
                    <p class="md:mb-4 mb-6"><b>La vida se convierte en un escenario donde cada acción y palabra es cuidadosamente calculada para maximizar el impacto en los medios y el drama.</b></p>
                    <p class="md:mb-4 mb-6">Desde el punto de vista sociológico, esto plantea importantes preguntas sobre la autenticidad en los medios: <b>¿Cuánta realidad queda en los reality shows?</b>, ¿y qué impacto tiene en la percepción de la realidad por parte del público?</p>
                    <p class="md:mb-4 mb-6">La teoría crítica sugiere que, a medida que la vida se transforma en espectáculo, la distinción entre lo real y lo representado se difumina (hiperrealidad) <b>creando una cultura donde el consumo de la “realidad” es, simplemente, una forma más de entretenimiento</b>.</p>
                    <p class="mb-20">A medida que los realitys shows  evolucionen con las nuevas tecnologías, los estudios sobre el impacto cultural de éstos seguirán siendo relevantes en los campos de la sociología y la comunicación. <b>¿Tú qué opinas?</b></p>
                    <div class="flex justify-between md:mb-8 mb-10">
                        <LikesVue :articleID="code"/>
                        <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
                    </div>
                </div>
                <div class="flex justify-start items-start">
                    <img src="/images/barra-art.png" class="w-[15%]">
                </div>
            </div>
            <SigArt :ant="true" :linkAnt="'/academico/licenciatura/arte-contemporaneo-en-mexico'" :linkSig="'/aprende/tendencias/la-casa-de-los-famosos-mexico-2-analisis'" :sig="true"/>
            <ConComentarios :article="code" class="pb-12"/>
            <Comentarios :article="code" class="pb-12"/>
        </div>
    </div>
</template>

<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0073-ART-APRENDE-TENDENCIAS-130924',
            ruta: '',
            title: '',
            image: '',
            text: 'Explora cómo los reality shows, desde su origen hasta los más actuales, reflejan cambios sociales y comunicativos en la cultura global.'
        }
    },
}
</script>

<style>

</style>