<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32  w-full font-sans text-black">
        <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">La Casa De Los Famosos México 2: <br class="md:block hidden"> ¿el rey del rating?</h1>
        <div class="flex flex-col justify-center items-center w-full">
            <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
                <img src="/images/casa-famosos-mexico.webp" alt="La Casa De Los Famosos México 2 ha roto récords de rating en México." class="mb-6">
                <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
                    <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tendencias" class=" text-hone">Tendencias</router-link></p>
                    <div class="flex items-start justify-start">
                        <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">13 de septiembre de 2024</li>  <li>Iván Solís Rivera</li></ul>
                    </div>
                    <p class="md:mb-4 mb-6">De acuerdo con Televisa Univisión, el reality de <b>La Casa De Los Famosos México 2024</b> (LCDLFM 2) es el programa de televisión con mayor rating en la historia de la televisión mexicana y también el que más atención <b>ha generado en las redes sociales con 13 mil millones de reproducciones de video (video-views)</b>.</p>
                    <p class="md:mb-4 mb-6">“<b>Es un hito en la historia de la televisión mexicana</b> y de las redes sociales a nivel mundial”, indica el medio de comunicación. Esta afirmación se constata por la especialista en medición de audiencia de medios, <b>Nielsen, cuyas mediciones indican</b> que durante el episodio final de la temporada pasada, <b>este reality show tuvo un récord histórico de 20 millones de televidentes</b>.</p>
                    <p class="md:mb-4 mb-6">En la anterior edición para elegir a la ganadora, que fue <b>Wendy Guevara, se lograron alrededor de 130 millones de votos del público</b>.</p>
                    <p class="md:mb-4 mb-6">Recientemente, <b>la agencia de investigación Nielsen IBOPE (Instituto Brasileño de Opinión Pública) reportó que el pasado 5 de agosto, el programa obtuvo 1.80 millones de televidentes</b>, mientras que <b>al día siguiente, este reality obtuvo 1.89 millones</b> de espectadores. Resalta que en estas dos fechas se suscitó una acalorada discusión entre dos integrantes del programa, derivando en insultos mutuos.</p>
                    <p class=" md:mb-8 mb-10"><b>En su emisión del 11 de agosto en la edición 2024, la cifra de rating fue de 3.7 millones de espectadores, según la agencia. Con base en lo anterior, el reality obtuvo una ventaja de tres a uno respecto a la televisora de la competencia directa, TV Azteca</b>, con su programa: “La Academia”, el cual tuvo 1 millón de espectadores.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">LCDLFM 2: Un producto de entretenimiento</h2>
                    <p class="md:mb-4 mb-6">Con los altos índices de rating, <b>La Casa De Los Famosos 2 es considerado un producto de entretenimiento</b>, el cual es consumido por grandes volúmenes de audiencia.</p>
                    <p class="md:mb-4 mb-6">Este reality se inscribe en la lógica de los medios masivos, que <b>busca maximizar la audiencia y el consumo de contenido al presentar una narrativa abierta</b> y en constante desarrollo, además de estimular el consumo continuo.</p>
                    <p class="md:mb-4 mb-6">Desde una perspectiva de la industria cultural, <b>este formato ofrece un contenido fácilmente consumible, adaptable y reproducible en distintos contextos sociales</b> y culturales, convirtiéndose en una mercancía global, como lo <b>señala, el semiólogo, Umberto Eco</b>.</p>
                    <p class="md:mb-4 mb-6"><b>Como producto de entretenimiento de masas, el programa no solo entretiene, sino que también influye en los temas que se discuten socialmente</b>, colocando temas de la vida cotidiana y la convivencia.</p>
                    <p class="md:mb-8 mb-10">Por tanto, <b>LCDLFM 2 combina múltiples elementos de la cultura de masas y las dinámicas de comunicación</b>, lo que lo posiciona como un entretenimiento diseñado para capturar y mantener la atención del espectador.</p>
                    <img src="/images/lcdlfm2.webp" alt="LCDLFM 2 ha integrado elementos sociales y psicológicos en su contenido." class="md:mb-8 mb-10">
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cuáles son sus ganancias y derrama económica de este reality show?</h2>
                    <p class="md:mb-4 mb-6">Como se mencionó anteriormente, <b>el reality de la casa de los famosos</b>, es un producto de entretenimiento, <b>cuyo principal elemento es el rating o la cuota de pantalla</b>, que se refiere a la cantidad de espectadores que están viendo un programa de televisión.</p>
                    <p class="md:mb-4 mb-6">En ese sentido, el creador digital <b>Raúl Gutiérrez reveló que Televisa </b>“<b>está feliz con los resultados del reality 'La Casa De Los Famosos', tanto de rating como monetarios, pues se están embolsando hasta 450 millones de pesos MXN</b> semanales, entre venta de espacios publicitarios, menciones, etc."</p>
                    <p class="md:mb-4 mb-6">Referente a las ganancias de los participantes, es de dominio público que <b>el ganador de este reality podrá llevarse una suma de 4 millones de pesos MXN</b>. Sin embargo, esta no es la única forma en que los participantes puedan obtener ingresos.</p>
                    <p class="md:mb-4 mb-6">De acuerdo con la información de la conductora y ex-integrante, Shanik <b>cada uno de los integrantes mantiene un contrato con Televisa</b> en el que se establece la suma monetaria que recibirán semanalmente.</p>
                    <p class="md:mb-4 mb-6">La cifra se establece de acuerdo a la fama de cada personalidad y a la negociación efectuada entre los y la cadena televisiva. Esto provoca que los sueldos no sean similares.</p>
                    <p class="md:mb-4 mb-6">En el caso de <b>Adrián Marcelo</b>, el youtuber confesó que aceptó participar en el programa por una suma de <b>450 mil pesos MXN para su pago semanal</b>.</p>
                    <p class="md:mb-4 mb-6">Por otro lado, <b>Gala Montes recibe una cifra aproximada de 80 mil pesos MXN</b> cada semana. De acuerdo con medios de comunicación, la actriz es la participante menos pagada del reality.</p>
                    <p class="md:mb-4 mb-6">Mientras que el presentador y comediante <b>Mario Bezares recibe una cantidad semanal de 525 mil pesos MXN</b>, según información manejada.</p>
                    <p class="md:mb-8 mb-10">No todo han sido todo ganancias, ya que <b>las polémicas que se han generado dentro de la Casa han provocado pérdidas millonarias que llegan hasta 300 millones de pesos MXN</b>, perdiendo patrocinio de marcas como Mercado libre, Helados Holanda, Unilever, Didi, Domino´s Pizza, Vips y Nestlé.</p>
                    <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                        <ContactoVue class="m-3"/>
                    </div>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué hay detrás de La Casa De Los Famosos?: cotidianidad, polémicas, chismes y voyeurismo</h2>
                    <p class="md:mb-8 mb-10"><b>Este programa ha combinado una serie de <router-link to="/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental" class=" text-htwo hover:text-hone">elementos sociales y psicológicos</router-link> que atraen a las grandes audiencias</b> con el fin de mantener altos índices de audiencia. Entre los aspectos que destacan, son los siguientes:</p>
                    <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Conocer la vida cotidiana de los famosos</h3>
                    <p class="md:mb-4 mb-6">Según Esmeralda Correa Cortez, investigadora del Departamento de Políticas Públicas, del Centro Universitario de Ciencias Económico Administrativas (CUCEA). <b>La idea de observar a las celebridades en su día a día, es explotada en este tipo de productos televisivos y que se ha replicado con éxito en bio-series de famosos</b>.</p>
                    <p class="md:mb-8 mb-10">“<b>Es esa sensación de ver cómo se pueden comportar los famosos en la ‘cotidianidad’ y lo digo entre comillas porque no es una cotidianidad real</b>, es una realidad montada, pero luego a la conciencia se le olvida y sientes que es real”, refiere.</p>
                    <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Chismes y rumores</h3>
                    <p class="md:mb-4 mb-6">En su libro Sapiens, el historiador Yuval Harari, señala que el chisme ha sido un elemento estructural para la supervivencia de la raza humana. “<b>Hasta donde sabemos, solo los sapiens pueden hablar acerca de tipos enteros de entidades que nunca han visto, ni tocado, ni olido</b>”.</p>
                    <p class="md:mb-8 mb-10">Lo anterior se ve reflejado en este tipo de realitys, ya que <b>una de las estrategias de los participantes es difundir rumores sobre otros para alargar su permanencia</b> dentro del programa.</p>
                    <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Voyeurismo</h3>
                    <p class="md:mb-4 mb-6">Los expertos señalan que “<b>somos voyeristas por naturaleza, nos gusta ver y como en su momento supo detectar Big Brother</b>. A las audiencias les gusta el poder ver todo lo que sucede en una casa a muchas personas, sobre todo si tienen cierta fama.”</p>
                    <p class="md:mb-8 mb-10"><b>Ser el ojo que lo ve casi todo es una fantasía que el cine y la televisión han ayudado a construir</b> y este formato es la posibilidad última que se juega dentro de una sociedad que ha sido domesticada bajo el lente del espectáculo, coinciden especialistas en Psicología social.</p>
                    <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Polémicas y violencia psicológica</h3>
                    <p class="md:mb-4 mb-6">De acuerdo con Sarah García Silbermann del Instituto Mexicano de Psiquiatría, <b>los medios de comunicación, y en particular la televisión, han utilizado a la violencia como elemento central de su programación</b>, a lo largo de toda su historia, de manera cada vez más intensa.</p>
                    <p class="md:mb-8 mb-10">Es el caso de La Casa De Los Famosos, <b>en la que podemos observar fricciones y diálogos cargados de <router-link to="/blog/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas" class=" text-htwo hover:text-hone">violencia psicológica</router-link> entre los participantes</b>, debido a las dinámicas sociales que se dan entre ellos o a la sensación de encierro que experimentan.</p>
                    <img src="/images/quien-ganara-casa-famosos-mexico-2.webp" alt="¿Quién ganará La Casa De Los Famosos México 2?, debe de reunir ciertas características." class="md:mb-8 mb-10">
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Quién ganará La Casa De Los Famosos México 2?</h2>
                    <p class="md:mb-4 mb-6">Especialistas en sociología, <b>indican que los ganadores de este tipo de formatos televisivos tienen ciertas características de personalidad</b> que sobresale de sus compañeros</p>
                    <p class="md:mb-8 mb-10"><b>El o la participante que ganará este reality show debe de reunir ciertos rasgos psicológicos</b>, como son las siguientes:</p>
                    <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Carisma</h3>
                    <p class="md:mb-8 mb-10">Uno de los rasgos característicos es el <b>carisma y autenticidad. Los concursantes que parecen genuinos y auténticos logran conectar con el público</b>, lo que es fundamental, ya que en muchas ocasiones los televidentes tienen un papel decisivo en su permanencia.</p>
                    <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Inteligencia social</h3>
                    <p class="md:mb-4 mb-6">En este tipo de formatos televisivos, <b>suelen establecer equipos o grupos que compiten entre sí por ciertos beneficios y ventajas</b>, por lo que tener inteligencia social es importante.</p>
                    <p class="md:mb-8 mb-10">Los finalistas en este tipo de programas suelen ser estratégicos en sus relaciones, <b>logrando alianzas y manteniendo una buena convivencia con el resto de los participantes</b>, lo que les permite evitar conflictos mayores y expulsiones tempranas.</p>
                    <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Resistencia emocional</h3>
                    <p class="md:mb-8 mb-10"><b>Los reality shows son emocionalmente agotadores, y los ganadores suelen demostrar fortaleza mental y emocional</b>, manejando bien el estrés, la presión social y la sensación de estar observado todo el tiempo.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
                    <p class="md:mb-4 mb-6">El formato de los realitys shows es atractivo para las grandes audiencias, en el que uno de los elementos importantes es atraer y cautivar a los espectadores. <b>Es responsabilidad de las productoras de este tipo de programas regular el tipo de contenidos que se trasmiten</b>.</p>
                    <p class="mb-20">Women and the Media: New Challenges. Review and Appraisal of the Implementation of the Beijing Declaration and Platform for Action señala que “afortunadamente, <b>los medios de comunicación no solo se conciben como agentes generadores de estereotipos, sino también como promotores de la diversidad, del multiculturalismo</b> y, sobre todo, del cambio relativo a la equidad de género.”</p>
                    <div class="flex justify-between md:mb-8 mb-10">
                        <LikesVue :articleID="code"/>
                        <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
                    </div>
                </div>
                <div class="flex justify-start items-start">
                    <img src="/images/barra-art.png" class="w-[15%]">
                </div>
            </div>
            <SigArt :ant="true" :linkAnt="'/academico/licenciatura/arte-contemporaneo-en-mexico'" :linkSig="'/aprende/tendencias/la-casa-de-los-famosos-mexico-2-analisis'" :sig="false"/>
            <ConComentarios :article="code" class="pb-12"/>
            <Comentarios :article="code" class="pb-12"/>
        </div>
    </div>
</template>

<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0072-ART-APRENDE-TENDENCIAS-130924',
            ruta: '',
            title: '',
            image: '',
            text: 'Mira que hay detrás de La Casa de los Famosos 2 y cómo va más allá del rating, explorando su impacto en el entretenimiento y la cultura de masas.'
        }
    },
}
</script>

<style>

</style>