<template>
    <div class="flex flex-col justify-center w-full font-sans text-black">
      <!-- Aquí iría el contenido de cada artículo -->
      <div class="flex flex-col items-center  justify-center w-full">
        <div class="flex flex-col max-w-5xl lg:max-w-[1200px] relative justify-start w-full">
          <div v-for="(article, index) in visibleArticles" :key="index" class="flex flex-col relative mb-6 justify-start bg-white text-justify border border-gray-300 w-full">
              <router-link :to="'/blog'+article.enlace" class="h-[80%] w-full"><img :src="article.portada" :alt="article.titulo" class=""></router-link>
              <div class="flex flex-col lg:m-8 m-4 justify-start items-start">
                  <router-link :to="'/blog'+article.enlace"><h2 class="font-serif text-left text-[16px] md:text-[24px] leading-none lg:text-[28px] mb-3 text-[#CAAB55]">{{ article.titulo }}</h2></router-link>
                  <div class="flex items-start justify-start">
                      <ul class="lg:flex list-disc pl-6 italic text-[14px] md:text-[16px] lg:text-[18px] mb-4 text-left" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-[#881912]">{{ article.fecha }}</li>  <li>{{ article.autor }}</li></ul>
                  </div>
                  <p class="text-justify md:tracking-normal tracking-tighter text-[16px] md:text-[18px] lg:text-[18px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;">{{ article.descripcion }}</p>
                  <div class="flex justify-start items-start">
                    <router-link :to="'/blog'+article.enlace"><button class="text-white transition duration-300 hover:text-black focus:border-[#881912] focus:outline-none font-serif md:text-[16px] flex items-center justify-center rounded-none bg-[#CAAB55] hover:bg-white border-[#CAAB55] md:w-[150px] h-[45px]">Leer más &#8594;</button></router-link>
                  </div>
              </div>
              <div class="flex justify-center items-center mx-4 mb-4  relative">
                <div class="flex justify-between w-full items-center absolute bottom-80">
                  <button @click="previousPage" class="focus:outline-none rounded-full text-[24px] bg-opacity-80 p-2 bg-white  "><svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10.707 3.293a1 1 0 011.414 1.414L7.414 10l4.707 4.707a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg></button> 
                  <button @click="nextPage" class="focus:outline-none  rounded-full text-[24px]  bg-opacity-80 p-2 bg-white  "><svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M9.293 16.707a1 1 0 01-1.414-1.414L12.586 10 7.879 5.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg></button> 
                </div>
              </div>
              <div class="flex items-end justify-end"><img src="../imagesComp/bar-home.png" alt="barra-final" class="w-[120px]"></div>
          </div>
          <!-- Aquí iría la paginación -->
          
        </div>
      </div>
    </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      articles: [], // Aquí se almacenarán los datos de los artículos
      articles2:[
        {
          "id": "0072-ART-APRENDE-TENDENCIAS-130924",
          "titulo": "La Casa De Los Famosos México 2: más allá del rating",
          "fecha": "13 de septiembre de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Mira que hay detrás de La Casa de los Famosos 2 y cómo va más allá del rating, explorando su impacto en el entretenimiento y la cultura de masas.",
          "portada": "/images/casa-famosos-mexico.webp",
          "enlace": "/aprende/tendencias/la-casa-de-los-famosos-mexico-2-analisis"
        },
        {
          "id": "0073-ART-APRENDE-TENDENCIAS-130924",
          "titulo": "Origen y evolución de los reality shows: una mirada sociológica y de comunicación",
          "fecha": "13 de septiembre de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Explora cómo los reality shows, desde su origen hasta los más actuales, reflejan cambios sociales y comunicativos en la cultura global.",
          "portada": "/images/origen-reality-show-big-brother-analisis-social-y-comunicacion.webp",
          "enlace": "/aprende/tendencias/origen-evolucion-reality-shows-sociologia-comunicacion-lcdelfm2"
        },
        {
          "id": "0071-ART-ACADEMICO-LICENCIATURA-050924",
          "titulo": "Arte contemporáneo en México: Evolución, movimientos y artistas",
          "fecha": "05 de septiembre de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Arte contemporáneo en México: Desde el muralismo hasta las expresiones vanguardistas actuales, reflejo de la influencia internacional y multiculturalidad.",
          "portada": "/images/arte-contemporaneo-mexico.webp",
          "enlace": "/academico/licenciatura/arte-contemporaneo-en-mexico"
        },
        {
          "id": "0066-ART-ACADEMICO-LICENCIATURA-030924",
          "titulo": "El coaching de vida: características y cómo podría ayudarte",
          "fecha": "03 de septiembre de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Explora qué es el coaching de vida, sus orígenes, tipos, beneficios y más.",
          "portada": "/images/que-es-coaching-de-vida-caracteristicas-beneficios.webp",
          "enlace": "/academico/licenciatura/coaching-de-vida-como-podria-ayudarte"
        },
        {
          "id": "0065-ART-SER-HUMANITAS-COMUNIDAD-030924",
          "titulo": "Universidad Humanitas campus Querétaro: Educación de alto nivel",
          "fecha": "03 de septiembre de 2024",
          "autor": "Redacción Humanitas",
          "descripcion": "La Universidad Humanitas campus Querétaro ofrece a la comunidad universitaria instalaciones de primer nivel y modalidades de estudio flexibles.",
          "portada": "/images/universidad-humanitas-campus-queretaro.webp",
          "enlace": "/ser-humanitas/comunidad/universidad-humanitas-campus-queretaro"
        },
        {
          "id": "0067-ART-ACADEMICO-LICENCIATURA-300824",
          "titulo": "¿Cómo superar una infidelidad?: sana y reconstruye tu relación",
          "fecha": "30 de agosto de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Conoce las facetas que pasa una pareja ante una infidelidad y cómo reconstruir tu vida emocional con confianza.",
          "portada": "/images/como-superar-infidelidad.webp",
          "enlace": "/academico/licenciatura/como-superar-una-infidelidad"
        },
        {
          "id": "0061-ART-ACADEMICO-LICENCIATURA-270824",
          "titulo": "¿Cómo prevenir el suicidio?: cuida tu salud mental",
          "fecha": "27 de agosto de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "El tema sobre cómo prevenir el suicidio es importante, conoce, desde la identificación de señales de alerta hasta la implementación de intervenciones para salvar vidas.",
          "portada": "/images/como-prevenir-suicidio.webp",
            "enlace": "/academico/licenciatura/como-prevenir-el-suicidio"
        },
        {
          "id": "0060-ART-SER-HUMANITAS-COMUNIDAD-260824",
          "titulo": "Universidad Humanitas campus Presa Madín: conócela",
          "fecha": "26 de agosto de 2024",
          "autor": "Redacción Humanitas",
          "descripcion": "El campus Magno Presa Madín de Universidad Humanitas: modernas instalaciones y estilo clásico para tu desarrollo académico.",
          "portada": "/images/universidad-campus-magno-presa-madin.webp",
          "enlace": "/ser-humanitas/comunidad/campus-presa-madin"
        },
        {
          "id": "0059-ART-SER-HUMANITAS-COMUNIDAD-230824",
          "titulo": "Universidad Humanitas Campus Magno Tijuana: Descubre tu potencial aquí",
          "fecha": "23 de agosto de 2024",
          "autor": "Redacción Humanitas",
          "descripcion": "Conoce las carreras, precios, RVOE y admisiones de Universidad Humanitas Tijuana. Todo lo que necesitas saber para estudiar.",
          "portada": "/images/universidad-humanitas-campus-magno-tijuana-excelencia-academica.webp",
          "enlace": "/ser-humanitas/comunidad/universidad-humanitas-tijuana-descubre-tu-potencial"
        },
        {
          "id": "0058-ART-APRENDE-TIPS-010824",
          "titulo": "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
          "fecha": "01 de agosto de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
          "portada": "/images/que-hacer-quedaste-ipn.webp",
          "enlace": "/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn"
        },
        {
          "id": "0057-ART-APRENDE-TIPS-310724",
          "titulo": "¿No te quedaste en la UNAM? Descubre estas alternativas",
          "fecha": "31 de julio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "¿Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
          "portada": "/images/no-te-quedaste-unam-universidad-humanitas.webp",
          "enlace": "/aprende/tips/no-te-quedaste-en-la-unam-descubre-estas-alternativas"
        },
        {
          "id": "0055-ART-APRENDE-TIPS-050724",
          "titulo": "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
          "fecha": "08 de julio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
          "portada": "/images/ansiedad-mismo-angustia.webp",
          "enlace": "/aprende/tips/ansiedad-es-lo-mismo-que-angustia"
        },
        {
          "id": "0053-ART-RECURSOS-CASOS-REALES-020724",
          "titulo": "Cómo identificar y superar relaciones tóxicas: Lo que debes de saber",
          "fecha": "02 de julio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Aprende a reconocer los signos de una relación tóxica y cómo prevenirlos para vivir una vida más sana y feliz.",
          "portada": "/images/identifica-relaciones-toxicas.webp",
          "enlace": "/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas"
        },
      ],
      currentPage: 1, // Página actual
      itemsPerPage: 1, // Número de artículos por página
    };
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      } else if (this.currentPage = 1) {
        this.currentPage = this.totalPages
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      } else if (this.currentPage = this.totalPages) {
        this.currentPage=1
      }
    },
    // Método para calcular el índice del primer artículo en la página actual
    calculateStartIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    // Método para calcular el índice del último artículo en la página actual
    calculateEndIndex() {
      return Math.min(this.currentPage * this.itemsPerPage, this.articles2.length);
    },

    //Metodos del formulario
    
  },
  computed: {
    // Cálculo del número total de páginas
    totalPages() {
      return Math.ceil(this.articles2.length / this.itemsPerPage);
    },
    // Filtrado de los artículos según la página actual
    visibleArticles() {
      const startIndex = this.calculateStartIndex();
      const endIndex = this.calculateEndIndex();
      return this.articles2.slice(startIndex, endIndex);
    },
  },
};
</script>


<style scoped>

</style>