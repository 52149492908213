import art_007 from "../../pages/Blog/Aprende/Tendencias/07-ART-APRENDE-TENDENCIAS-190224.vue"
import art_004 from "../../pages/Blog/Aprende/Tendencias/04-ART-APRENDE-TENDENCIAS-310124.vue"
import art_015 from "../../pages/Blog/Aprende/Tendencias/15-ART-APRENDE-TENDENCIAS-270324.vue"
import art_0028 from "../../pages/Blog/Aprende/Tendencias/0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524.vue"
import art_0073 from "../../pages/Blog/Aprende/Tendencias/0073-ART-APRENDE-TENDENCIAS-130924.vue"
import art_0072 from "../../pages/Blog/Aprende/Tendencias/0072-ART-APRENDE-TENDENCIAS-130924.vue"

export default [
    {
        path: '/blog/aprende/tendencias/la-casa-de-los-famosos-mexico-2-analisis',
        name: 'La Casa De Los Famosos México 2: más allá del rating',
        meta: {
          title: "La Casa De Los Famosos México 2: más allá del rating",
          requireAuth: false,
        },
        component: art_0072
    },
    {
        path: '/blog/aprende/tendencias/origen-evolucion-reality-shows-sociologia-comunicacion-lcdelfm2',
        name: 'Origen y evolución de los reality shows: una mirada sociológica y de comunicación',
        meta: {
          title: "Origen y evolución de los reality shows: una mirada sociológica y de comunicación",
          requireAuth: false,
        },
        component: art_0073
    },
    {
        path: '/blog/aprende/tendencias/tendencias-administracion-empresas-2024',
        name: 'Estas son las tendencias clave en la administración en 2024, descúbrelas.',
        meta: {
          title: "Estas son las tendencias clave en la administración en 2024, descúbrelas.",
          description: "Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.",
          image: "https://blog.humanitas.edu.mx/images/meta/Tendencias-administracion-empresarial-2024-multinube.jpg",
          metaTags:
          {
            "og:title": "Estas son las tendencias clave en la administración en 2024, descúbrelas.",
            "og:description": "Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Tendencias-administracion-empresarial-2024-multinube.jpg",
          },
          requireAuth: false,
        },
        component: art_004
    },
    {
        path: '/blog/aprende/tendencias/tendencias-actuales-del-derecho-2024',
        name: 'Infórmate acerca de las tendencias actuales del Derecho en 2024.',
        meta: {
          title: "Infórmate acerca de las tendencias actuales del Derecho en 2024.",
          description: "Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.",
          image: "https://blog.humanitas.edu.mx/images/meta/Tendencias-actuales-derecho-2024.jpg",
          metaTags:
          {
            "og:title": "Infórmate acerca de las tendencias actuales del Derecho en 2024.",
            "og:description": "Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Tendencias-actuales-derecho-2024.jpg",
          },
          requireAuth: false,
        },
        component: art_007
    },
    {
        path: '/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental',
        name: 'Tendencias en Psicología para 2024: Tecnología en la salud mental',
        meta: {
          title: "Tendencias en Psicología para 2024: Tecnología en la salud mental",
          description: "Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.",
          image: "https://blog.humanitas.edu.mx/images/meta/Tendencias-psicología-2024-chico-sentado-meditando-frente-laptop.jpg",
          metaTags:
          {
            "og:title": "Tendencias en Psicología para 2024: Tecnología en la salud mental",
            "og:description": "Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Tendencias-psicología-2024-chico-sentado-meditando-frente-laptop.jpg",
          },
          requireAuth: false,
        },
        component: art_015
    },
    {
        path: '/blog/aprende/tendencias/competencias-digitales-para-profesionales',
        name: 'Mejores competencias digitales para profesionales',
        meta: {
          title: "Mejores competencias digitales para profesionales",
          description: "Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.",
          image: "https://blog.humanitas.edu.mx/images/meta/competencias-digitales-profesionales-digitalizacion.webp",
          keywords: "",
          metaTags:
          {
            "og:title": "Mejores competencias digitales para profesionales",
            "og:description": "Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/competencias-digitales-profesionales-digitalizacion.webp",
            "og:keywords": "",
          },
          requireAuth: false,
        },
        component: art_0028
    },
]